import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "core-functionality"
    }}>{`Core functionality`}</h1>
    <p>{`Every app needs to `}<em parentName="p">{`do`}</em>{` something. Always best to build that first :)`}</p>
    <p>{`We're going to make it easy for people to convert twitter URLs into screenshots.`}</p>
    <p>{`Here's a link to an AWS Lambda that I prepared earlier: `}<inlineCode parentName="p">{`https://pifc233qp6.execute-api.us-east-1.amazonaws.com/dev/embed?url=`}</inlineCode>{`. It takes tweet URLs and turns them into screenshots.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media1.giphy.com/media/ARAVYYOfzdFiYghGfa/giphy-loop.mp4?cid=4ac046a2twsljiigbzvlnsjjpyj03ncm5t3aq1p07l7smnm8&rid=giphy-loop.mp4",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <h2 {...{
      "id": "steps"
    }}>{`Steps`}</h2>
    <ol>
      <li parentName="ol">{`Accept a tweet URL from user input`}</li>
      <li parentName="ol">{`Send to Lambda`}</li>
      <li parentName="ol">{`Display result`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      